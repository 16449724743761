<template>
  <div id="account-edit">
    <Headline />
    <AccountEdit />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import AccountEdit from '../../components/account/AccountEdit'

export default {
  components: {
    Headline,
    AccountEdit
  }
}
</script>
