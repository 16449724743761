<template>
  <v-card>
    <v-card-text>
      <v-form ref="form" @submit.prevent="sendToStore()">
        <!-- @todo by now only readonly since data can only be persisted to the store and not to the api -->
        <v-text-field
          v-for="(value, key) in userData"
          :key="key"
          :label="key"
          :value="userData[key]"
          readonly
          @input="updateFormData(key, $event)"
          filled
          dense
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      formData: {}
    }
  },
  computed: {
    userData() {
      return this.$store.getters['user/getUserData']
    }
  },
  methods: {
    ...mapActions('user', ['saveData']),
    updateFormData(field, value) {
      this.formData[field] = value
    },
    sendToStore() {
      return this.saveData(this.formData)
    }
  }
}
</script>
